import './App.css';
import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext'; 
import IndexPage from './IndexPage';
import CauseDetails from './CauseDetails';
import EventDetails from './EventDetails';
import OrganizationDetails from './OrganizationDetails';
import SubmitCause from './SubmitCause';
import SubmitOrganization from './SubmitOrganization';
import SubmitEvent from './SubmitEvent';
import Feedback from './Feedback';
import Header from './Header';
import Signup from './SignUp';
import Login from './Login';
import MyCauses from './MyCauses';
import AllCauses from './AllCauses';
import UserPasswordReset from './UserPasswordReset'; 
import UserProfile from './UserProfile';


//Protected Routes: Functionality for checking User role and allowing access accordingly.

const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const { currentUser } = useAuth();
  
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const userRole = currentUser.get('role');
  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

function App() {

  const [isParseInitialized, setIsParseInitialized] = useState(false);

  const initializeParse = () => {
    const app_id = process.env.REACT_APP_PARSE_APP_ID;
    const host_url = process.env.REACT_APP_PARSE_HOST_URL;
    const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

    if (app_id && host_url && javascript_key) {
      Parse.initialize(app_id, javascript_key);
      Parse.serverURL = host_url;
      console.log("Parse initialized successfully with app_id:", app_id);
      setIsParseInitialized(true); // Set the state to true after initialization
    } else {
      console.error("Parse initialization failed due to missing environment variables");
      setIsParseInitialized(false); // Initialization failed
    }
  };

  useEffect(() => {
    initializeParse(); // Initialize Parse on app load
  }, []);

  return (
    <AuthProvider isParseInitialized={isParseInitialized}>
      <Router>
        <div className="App">
        <Header />
          <Routes>
            <Route path="/" exact element={<IndexPage />} />
            <Route path="/cause/:id" element={<CauseDetails />} />
            <Route path="/organization/:id" element={<OrganizationDetails />} />
            <Route path="/event/:id" element={<EventDetails />} />
            <Route path="/submit-cause" element={<ProtectedRoute allowedRoles={['Organization']} element={SubmitCause } />} />
            <Route path="/edit-cause/:id" element={<SubmitCause />} />
            <Route path="/my-causes" element={<MyCauses />} />
            <Route path="/all-causes" element={<AllCauses />} />
            <Route path="/submit-cause/:id" element={<SubmitCause />} />
            <Route path="/submit-organization" element={<SubmitOrganization />} />
            <Route path="/reset" element={<UserPasswordReset />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/feedback" element={<Feedback />} />
            {/* 
            
            <Route path="/submit-event" element={<SubmitEvent />} />
            */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
