import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { Menu, Button, Dropdown, Avatar, message, Drawer } from 'antd';
import { UserOutlined, MenuOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const { SubMenu } = Menu;

const Header = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (currentUser) {
      console.log('Current user ID:', currentUser.id, 'Current user Name:', currentUser.getUsername());
    } else {
      console.log('No user is currently logged in.');
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      message.success('You have successfully logged out.');
      navigate('/');
    } catch (error) {
      message.error('Failed to log out. Please try again.');
      console.error('Logout error:', error);
    }
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Button type="link" onClick={handleLogout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  // Determine the current selected key based on the path
  const getSelectedKey = () => {
    const path = location.pathname;
    if (path === '/') return 'home';
    if (path.startsWith('/my-causes')) return 'my-causes';
    if (path.startsWith('/submit-cause')) return 'submit-cause';
    if (path.startsWith('/profile')) return 'profile';
    return '';
  };

  return (
    <div className="header">
      <Button 
        className="menu-button"
        type="text"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title=""
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
        closeIcon={<LeftOutlined />}
        bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <div style={{ flex: 1 }}>
        <Menu
          mode="vertical"
          selectedKeys={[getSelectedKey()]}
          onClick={() => setVisible(false)} // Close the drawer when a menu item is clicked
        >
          {currentUser && currentUser.get('role') === 'Volunteer' && (
            <>
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="all-causes">
                <Link to="/all-causes">Cause List</Link>
              </Menu.Item>
              <Menu.Item key="my-causes">
                <Link to="/my-causes">My Causes</Link>
              </Menu.Item>
              <Menu.Item key="feedback">
                <Link to="/feedback">Submit Feedback</Link>
              </Menu.Item>
              
            </>
          )}
          {currentUser && currentUser.get('role') === 'Organization' && (
            <>
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="submit-cause">
                <Link to="/submit-cause">Submit Cause</Link>
              </Menu.Item>
              <Menu.Item key="all-causes">
                <Link to="/all-causes">Cause List</Link>
              </Menu.Item>
              <Menu.Item key="my-causes">
                <Link to="/my-causes">My Causes</Link>
              </Menu.Item>
              <Menu.Item key="feedback">
                <Link to="/feedback">Submit Feedback</Link>
              </Menu.Item>
            </>
          )}
          {!currentUser && (
            <>
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="all-causes">
                <Link to="/all-causes">Cause List</Link>
              </Menu.Item>
              <Menu.Item key="feedback">
                <Link to="/feedback">Submit Feedback</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
        </div>
        {currentUser ? (
          <div className="drawer-user-menu">
            <Menu 
              mode="inline"
            >
              <SubMenu 
                icon={<UserOutlined />} 
                title={currentUser.get('username')}
              >
                <Menu.Item key="profile">
                  <Link to="/profile">My Profile</Link>
                </Menu.Item>
                <Menu.Item key="logout">
                  <Link to="/" onClick={handleLogout}> Logout </Link>
                </Menu.Item>
              </SubMenu>
              
            </Menu>
          </div>
        ) : (
          <div className="drawer-auth-links">
            <Link to="/login" onClick={() => setVisible(false)}>Log In</Link>
            <Link to="/signup" onClick={() => setVisible(false)}>Sign Up</Link>
          </div>
        )}
      </Drawer>
     
    </div>
  );
};

export default Header;
