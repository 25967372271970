import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Button, Card, Row, Col, Typography, Form, Input, message, Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import ChatDetail from './ChatDetail.js';
import EmailAssistant from './EmailAssistant.js';

const { Title, Paragraph } = Typography;

const CauseDetails = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [cause, setCause] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [recommendedEmailMessage, setRecommendedEmailMessage] = useState('');
  const [form] = Form.useForm();

  // Fetch the data from session storage if it exists
  useEffect(() => {
    const causeDataFromStorage = JSON.parse(sessionStorage.getItem('causeData'));
    console.log('Cause data from session storage:', causeDataFromStorage);
    if (causeDataFromStorage) {
      setCause(causeDataFromStorage);
    }

    // Whenever the recommendedEmailMessage changes, set it in the form's 'message' field
    form.setFieldsValue({ message: recommendedEmailMessage });


  }, [currentUser, recommendedEmailMessage, form]);

  if (!cause) return <p>Loading...</p>;

  

  // Parse the data directly from the stored causeData
  const organizationName = cause.cause_name || 'Organization name not available';
  const organizationWebsite = cause.orgainzation_website || 'Website not available';
  const organizationMission = cause.short_description || 'Mission not available';
  const longDescription = cause.long_description || null;
  const volunteerNeeds = cause.volunteer_needs || 'Volunteer needs not available';
  const contactEmail = cause.email_address || 'Email not available';
  const currentUserEmail = currentUser ? currentUser.get('email') : 'Email not available';
  const userInterests = cause.user_interests || 'No interests provided';
  const userSkills = cause.user_skills || 'No skills provided';

  const handleContactClick = () => {
    if (currentUser) {
      setShowForm(!showForm); // Show form if the user is logged in
    } else {
      setShowModal(true); // Show modal if the user is not logged in
    }
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
  };

  const handleMoreInfoClick = () => {
    setShowDetailForm(!showDetailForm);
    console.log('Give me more');
  }

  const sendEmail = async (to, from, messageContent) => {
    try {
      console.log('Calling Cloud Code to send email');
      // Call the Cloud Code function to send the email
      const result = await Parse.Cloud.run('sendEmail', {
        to: to,
        from: 'dmevans89@sandiegoeco.org',
        subject: 'New Request from Local Cause App',
        message: messageContent,
        replyTo: from
      });
      console.log('Cloud Code Response:', result);
      message.success(result); // Display success message
    } catch (error) {
      console.error('Cloud Code Error:', error);
      message.error('Error sending email: ' + error.message); // Display error message
    }
  };

  const onFinish = async (values) => {
    const { to, from, message: messageContent } = values;
    await sendEmail(to, from, messageContent);  // Call sendEmail
    setEmailSent(true);
  };


  return (
    <div className="cause-details" style={{displayContent: 'center'}}>
      
      <Card style={{ margin: '2%' }}>

        {/* Organization Name */}
        <Title level={2}>{organizationName}</Title>

        {/* Organization Mission */}
        <Paragraph style={{ fontSize: '18px' }}>
          <strong>Mission: </strong>
          <br />
          {organizationMission}
        </Paragraph>

        {/* Volunteer Needs */}
        <Paragraph>
          <strong>Volunteer Needs: </strong>
          <br />
          {volunteerNeeds}
        </Paragraph>

        {longDescription ? (
          <Paragraph>
            <strong>More Detail:</strong>
            {longDescription}
          </Paragraph>
        ) : null}

         

        <Row gutter={16} style={{ marginTop: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="primary" onClick={handleContactClick} style={{ margin: '2px' }}>
              Contact
            </Button>
            
            <Button onClick={handleMoreInfoClick} style={{margin: '2px'}}>
              More Info
            </Button>
            
        </Row>
      </Card>

      {/* Conditional CONTACT Form Rendering */}
        {showForm && (
          <Card style={{margin: '2%', marginTop: '5px'  }}>
            {emailSent ? ( 
              <Paragraph style={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>
              Contacted!
              </Paragraph>
            ) : ( 
          <Form form={form} onFinish={onFinish} style={{ marginTop: 20 }}>
            <Form.Item label="To" name="to" initialValue={contactEmail}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="From" name="from" initialValue={currentUserEmail}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Message" name="message">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} />
            </Form.Item>

              {/* EmailAssistant component to generate message */}
              <EmailAssistant
                userInterests={userInterests}
                userSkills={userSkills}
                setRecommendedEmailMessage={setRecommendedEmailMessage}
              />

            <Form.Item style={{ margin: '2px'}}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
            )}
          </Card>
        )}

        {/* Conditional DETAIL Form Rendering */}
          {showDetailForm && (
            <ChatDetail 
              organizationName={organizationName} 
              organizationWebsite={organizationWebsite}
              userInterests={userInterests}
              userSkills={userSkills}
            />
          )}

      {/* Modal for login prompt */}
      <Modal
        title="Please Login"
        visible={showModal}
        onCancel={handleModalClose}
        footer={null}
      >
        <Paragraph>Please <Link to={`/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`}>login</Link> to contact organizations.</Paragraph>
      </Modal>
    </div>
  );
};

export default CauseDetails;