import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import './IndexPage.css';
import { Row, Col, Select, Spin } from 'antd';
import CustomCard from './CustomCard';
import { useAuth } from './AuthContext';
import Chat from './Chat'; 

const { Option } = Select;

const IndexPage = () => {
  const { currentUser, isInitialized } = useAuth();
  const [causes, setCauses] = useState([]);
  const [botCauses, setBotCauses] = useState([]);
  

  const handleOptionsReceived = (options) => {
    setBotCauses(options);
  };
  

  return (
    <div style={{display: 'block', maxWidth: '95%', margin: '2%'}}>
      <div className="chat-home" >
      <Col span={24} style={{ height: '50vh', overflowY: 'auto' }}>
        <Chat onOptionsReceived={handleOptionsReceived} />
      </Col>
      </div>
        <div className="cards">
          {botCauses.length > 0 && (
            <div className="cards-container">
              {botCauses.map((cause, index) => (
                <div key={index} className="card">
                  <CustomCard
                    
                    type="Cause"
                    data={{
                      id: cause.organization_name,
                      cause_name: cause.organization_name,
                      short_description: cause.mission,
                      location: cause.location,
                      volunteer_needs: cause.volunteer_opportunities,
                      email_address: cause.email_address,
                      organization_website: cause.website,
                      user_interests: cause.user_interests,
                      user_skills: cause.user_skills

                    }}
                  />
                </div>
              ))}
            </div>
          )}
    </div>
    </div>
  );
  
  
};

export default IndexPage;
