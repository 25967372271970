import React, { useState, useEffect } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const SubmitOrganization = () => {
  const [name, setName] = useState('');
  const [mission, setMission] = useState('');
  const [details, setDetails] = useState('');
  const [website, setWebsite] = useState('');
  const [image1, setImage1] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkParseInitialization = () => {
      const app_id = process.env.REACT_APP_PARSE_APP_ID;
      const host_url = process.env.REACT_APP_PARSE_HOST_URL;
      const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

      if (app_id && host_url && javascript_key) {
        Parse.initialize(app_id, javascript_key);
        Parse.serverURL = host_url;
        console.log('Parse initialized successfully');

        const currentUser = Parse.User.current();
        console.log('Current User after initialization:', currentUser);
      } else {
        console.error("Parse initialization failed due to missing environment variables");
      }
    };

    checkParseInitialization();
  }, []);

  const handleSubmit = async (values) => {
    const { organizationName, mission, details, website } = values;

    // Get the current logged-in user
    const currentUser = Parse.User.current();
    console.log('Current User:', currentUser); // Debug log for current user

    if (!currentUser) {
      message.error('No user logged in');
      return;
    }

    const Organization = Parse.Object.extend('Organizations');
    const organization = new Organization();

    organization.set('organization_name', organizationName);
    organization.set('mission', mission);
    organization.set('details', details);
    organization.set('website', website);
    if (image1) {
      const parseFile = new Parse.File("image1.png", { base64: image1.split(',')[1] });
      organization.set('image_1', parseFile);
    }

    // Set the owner pointer to the current user
    organization.set('owner', currentUser);

    setLoading(true);

    try {
      await organization.save();
      message.success('Thanks for taking care of that! You can now submit a Cause.');
      navigate('/');
    } catch (error) {
      message.error('Failed to submit organization: ' + error.message);
      console.error('Submit error:', error); // Additional error logging
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1>Organization Details</h1>
      <h3>Please provide a few details about your organization. You can edit this information any time within your profile.</h3>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Organization Name" name="organizationName" rules={[{ required: true, message: 'Please input the name of your organization!' }]}>
          <Input placeholder="Name of your organization" />
        </Form.Item>
        <Form.Item label="Mission" name="mission" rules={[{ required: true, message: 'Please input your mission!' }]}>
          <Input.TextArea placeholder="Briefly state your mission" />
        </Form.Item>
        <Form.Item label="Details" name="details" rules={[{ required: true, message: 'Please provide more detail about your organization!' }]}>
          <Input.TextArea placeholder="Provide more detail about your organization" />
        </Form.Item>
        <Form.Item label="Website" name="website" rules={[{ required: true, message: 'Please input your website!' }]}>
          <Input placeholder="Website" />
        </Form.Item>
        <Form.Item label="Image">
          <Upload
            name="image"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                setImage1(e.target.result);
              };
              reader.readAsDataURL(file);
              return false; // Prevent upload
            }}
          >
            {image1 ? <img src={image1} alt="image" style={{ width: '100%' }} /> : <div><PlusOutlined /><div style={{ marginTop: 8 }}>Upload</div></div>}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubmitOrganization;
