import React, { createContext, useState, useEffect, useContext } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { Spin } from 'antd';

const AuthContext = createContext();

const AuthProvider = ({ children, isParseInitialized }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Check and restore user session on app load
  useEffect(() => {
    if (!isParseInitialized) {
      console.log("Parse is not initialized yet");
      return;
    }
    console.log("Parse is initialized, checking user session");

    const checkUser = async () => {
      try {
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
          try {
            const user = await Parse.User.become(sessionToken);
            setCurrentUser(user);
            console.log("User session restored successfully");
          } catch (error) {
            console.error('Session restoration failed:', error);
            localStorage.removeItem('currentUser');
            localStorage.removeItem('sessionToken');
          }
        } else {
          console.log('No session token found in local storage');
        }
      } catch (error) {
        console.error('Error checking user session:', error);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('sessionToken');
      } finally {
        setIsInitialized(true);
        console.log("User session check complete");
      }
    };

    checkUser();
  }, [isParseInitialized]);

  // Login function
  const login = async (username, password) => {
    try {
      const loggedInUser = await Parse.User.logIn(username, password);
      localStorage.setItem('currentUser', JSON.stringify(loggedInUser.toJSON())); // Store user data
      localStorage.setItem('sessionToken', loggedInUser.getSessionToken()); // Store session token
      setCurrentUser(loggedInUser); // Set user in your app state
      console.log("User logged in successfully");
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Login failed: ' + error.message);
    }
  };

  // Registration function
  const register = async (email, username, password, role) => {
    const user = new Parse.User();
    user.set('username', username);
    user.set('password', password);
    user.set('email', email);
    user.set('role', role);

    try {
      const registeredUser = await user.signUp();
      localStorage.setItem('currentUser', JSON.stringify(registeredUser.toJSON()));
      localStorage.setItem('sessionToken', registeredUser.getSessionToken());
      setCurrentUser(registeredUser);
      setIsInitialized(true);
      console.log("User registered successfully");
    } catch (error) {
      console.error('Registration failed:', error);
      throw new Error('Registration failed: ' + error.message);
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await Parse.User.logOut();
      localStorage.removeItem('currentUser');
      localStorage.removeItem('sessionToken');
      setCurrentUser(null);
      setIsInitialized(true);
      console.log("User logged out successfully");
    } catch (error) {
      console.error('Logout failed:', error);
      throw new Error('Logout failed: ' + error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, register, logout, isInitialized, setCurrentUser }}>
      {isInitialized ? children : <Spin size="large" />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider };
