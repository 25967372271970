import React, { useState } from 'react';
import { Form, Input, Button, message as antMessage } from 'antd';
import Parse from 'parse/dist/parse.min.js';



const Feedback = () => {
  // State for form values
  const [formData, setFormData] = useState({
    contact: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create a new Parse object for the 'Feedback' class
    const Feedback = Parse.Object.extend('Feedback');
    const feedback = new Feedback();

    // Set values from the form
    feedback.set('contact', formData.contact);
    feedback.set('message', formData.message);

    try {
      // Save the feedback object to Back4App
      await feedback.save();
      antMessage.success('Thank you for your feedback!');

      // Clear form data
      setFormData({
        contact: '',
        message: '',
      });

      // Set submitted to true
      setSubmitted(true);
    } catch (error) {
      // Handle any errors
      antMessage.error('Failed to submit feedback. Please try again.');
      console.error('Error while saving feedback: ', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1>Feedback</h1>
      <p>Thank you for reporting bugs and sharing feedback.</p>
      <p>Your input will help us make this app more useful for volunteers.</p>
      <br></br>
      <p style={{ color: '#888', fontSize: '12px', marginTop: '-10px' }}>
        Your email will only be used for communication related to this feedback submission.
        </p>
      {submitted ? (
        <p style={{ fontSize: '20px' }}> Thank You! </p>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Item required>
            <Input
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
          </Form.Item>
            
          <Form.Item required>
            <Input.TextArea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Share your feedback here... the more specific the better!"
              required
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Feedback;
