import OpenAI from "openai";
import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, List, Empty } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import './Chat.css';

const openaiApi = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({ apiKey: openaiApi, dangerouslyAllowBrowser: true });

const Chat = ({ onOptionsReceived }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showWelcome, setShowWelcome] = useState(true); // New state for welcome sequence
    const [welcomeIndex, setWelcomeIndex] = useState(0);  // Track the current line in the sequence

    const welcomeMessages = [
        "Welcome...",
        "I'm here to help you find volunteer opportunities in San Diego.",
        "What type of causes are you interested in?"
    ];

    //Empty Component - to override Ant D standard 'no data' message
    const CustomEmpty = () => (
        <Empty 
          image={<SmileOutlined style={{ fontSize: 48 }} />} 
          description={<span></span>} 
        />
      );

    //Functiong to prevent 'enter' from creating a new line
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault(); // Prevents adding a new line
          sendMessage(); // Calls the send function
        }
      };

    // Function to simulate typing of welcome messages
    useEffect(() => {
        if (showWelcome && welcomeIndex < welcomeMessages.length) {
            const currentMessageLength = welcomeMessages[welcomeIndex].length;
            const typingSpeed = (currentMessageLength * 40, 1500);
            const timer = setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { user: 'Bot', text: welcomeMessages[welcomeIndex] }
                ]);
                setWelcomeIndex((prevIndex) => prevIndex + 1);
            }, typingSpeed); // Adjust timing to control typing speed
            return () => clearTimeout(timer);
        } else if (welcomeIndex >= welcomeMessages.length) {
            const finalDelay = 1000; // Delay of 2 seconds before adding the final message and ending the welcome sequence
            const timer = setTimeout(() => {
                setShowWelcome(false); // End the welcome sequence
            }, finalDelay);
            return () => clearTimeout(timer);
        }
    }, [welcomeIndex, showWelcome]);

    
    // Function to start a new thread and run the assistant on it
    const startThreadWithAssistant = async (assistantId) => {
        try {
            // Create a new thread
            const thread = await openai.beta.threads.create();

            // Create a run to attach the assistant to the thread
            const run = await openai.beta.threads.runs.create(thread.id, {
                assistant_id: assistantId,
            });

            setThreadId(thread.id);
            console.log('Thread started and run created with ID:', run.id);

        } catch (error) {
            console.error('Error starting thread with assistant:', error);
        }
    };


    // FEATURES: Loading, Scroll To Bottom
    const LoadingDots = () => {
        return (
            <span className="loading-dots">
                Working<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
            </span>
        );
    };

    const messagesEndRef = useRef(null); 

    // Scroll to the bottom of the chat
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();  // Call scrollToBottom whenever messages change
    }, [messages]);


    //Sending Messages & Receiving Answers
    const sendMessage = async () => {
      if (!input || !threadId) return;

      const newMessages = [...messages, { user: 'You', text: input }];
      setMessages(newMessages);
      setInput('');
      setIsLoading(true);
      

      try {
          // Add the message to the thread
          await openai.beta.threads.messages.create(threadId, {
              role: "user",
              content: input,
          });

          // Run the thread to get a response from the assistant
          const run = await openai.beta.threads.runs.createAndPoll(threadId, {
              assistant_id: 'asst_UuB5V07GGdhEGvtrIBy70B3J',
          });

          console.log('Full API Response:', run);

          // Check if the run is completed
          if (run.status === 'completed') {
              
            //retrieve list of all messages from thread
            const messagesResponse = await openai.beta.threads.messages.list(run.thread_id);
            console.log(messagesResponse);

            // Find the length of the messages array
            const messageCount = messagesResponse.data.length;
            console.log(messageCount);

            // Directly access the last message in the array
            const lastMessage = messagesResponse.data[0];
            console.log(lastMessage);

            // Initialize a variable to hold the bot's response
            let botMessage = null;

            // Assuming lastMessage.content[0].text.value contains the stringified JSON
            const parsedContent = JSON.parse(lastMessage.content[0].text.value);
              
              // Check if the last message is from the assistant
              if (lastMessage.role === 'assistant') {
                botMessage = parsedContent.message;
              }

            // If we got a response from the assistant, add it to the state
            if (botMessage) {
                setMessages([...newMessages, { user: 'Bot', text: botMessage }]);
            }

            // Check if the options array exists
              if (parsedContent.options && Array.isArray(parsedContent.options)) {
                //Set state variable as result
                setOptions(parsedContent.options);
                    if (onOptionsReceived) {
                        onOptionsReceived(parsedContent.options);  // Pass options to parent component
                    }

                // Loop through the options array
                parsedContent.options.forEach((option, index) => {
                    
                });
            } else {
                console.log("No options found in the response.");
            }
          
              
              
          } else {
              console.log(run.status);
              setMessages([...newMessages, { user: 'Bot', text: 'Sorry, I couldn’t process your request. Please try again.' }]);
          }
      } catch (error) {
          console.error('Error fetching response:', error);
          setMessages([...newMessages, { user: 'Bot', text: 'Sorry, something went wrong.' }]);
      } finally {
        scrollToBottom();
        setIsLoading(false);
    }
  };
    

            
            
  

    // Start the thread when the component mounts
    useEffect(() => {
        const assistantId = 'asst_UuB5V07GGdhEGvtrIBy70B3J'; // Replace with your actual assistant ID
        startThreadWithAssistant(assistantId);
    }, []);

    return (
        <div className="chat-container-home">
            <div className="messages-container-home">
                <List
                    locale={{ emptyText: <CustomEmpty />}}
                    dataSource={messages}
                    renderItem={(message, index) => (
                        <List.Item className={`message-item ${message.user === 'You' ? 'message-user' : 'message-bot'}`}>
                            {message.user === 'Bot' && <span className="bot-symbol"><SmileOutlined style={{ fontSize: 18 }} /></span>}
                            <div className={`message-${message.user === 'You' ? 'user' : 'bot'} ${showWelcome && index < 3 ? `line${index + 1}` : ''}`}>
                                {message.text}
                            </div>
                        </List.Item>
                    )}
                />
                
                <div ref={messagesEndRef} />
            </div>

            {isLoading && (
                    <div className="message-item message-bot">
                        <span className="bot-symbol"><SmileOutlined style={{ fontSize: 18 }} /></span>
                        <div className="message-bot">
                            <LoadingDots />
                        </div>
                    </div>
                )}
    
            <div className="input-container-home">
                <Input.TextArea 
                    className="chat-input"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Start with a cause you care about..."
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    disabled={showWelcome}
                />
                <button className="send-button" onClick={sendMessage}>
                    →
                </button>
            </div>
        </div>

        
    );
};

export default Chat;