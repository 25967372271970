import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import Parse from 'parse/dist/parse.min.js';
import { useAuth } from './AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const Login = () => {
  const { login } = useAuth();
  const [isParseInitialized, setIsParseInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (values) => {
    const { username, password } = values;
    try {
      await login(username, password);

      // Retrieve the 'redirectTo' parameter from the URL
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get('redirectTo');
      
      // Redirect back to the original page or to the homepage
      navigate(redirectTo || '/');

    } catch (error) {
      message.error('Error: ' + error.message);
    }
  };


  return (
    <div style={{ maxWidth: 400, margin: '0 auto' }}>
      <h2>Login</h2>
      <Form layout="vertical" onFinish={handleLogin}>
        <Form.Item  name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input placeholder="Username or Email" />
        </Form.Item>
        <Form.Item  name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>

        <Form.Item>
        <Link to={`/signup${location.search}`}>
          <Button >
            Sign Up
          </Button>
          </Link>
        </Form.Item>

        <Form.Item>
          <Link to="/reset">
          <Button type="secondary" >
            Forgot Password
          </Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
