import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import Parse from 'parse/dist/parse.min.js';
import { useAuth } from './AuthContext';

const { Meta } = Card;

const CustomCard = ({ type, data, actions = [], isSaved, onToggleSave }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const toggleSave = async (e) => {
    e.stopPropagation(); // Prevents the click event from bubbling up to the card's Link
    if (!currentUser || currentUser.get('role') !== 'Volunteer') return;

    const SavedCauses = Parse.Object.extend('SavedCauses');
    const query = new Parse.Query(SavedCauses);
    query.equalTo('user', currentUser.toPointer());

    try {
      const causeObject = await new Parse.Query('Causes').get(data.id);
      query.equalTo('cause', causeObject);

      const result = await query.first();

      if (result) {
        // Unsave the cause
        await result.destroy();
      } else {
        // Save the cause
        const savedCause = new SavedCauses();
        savedCause.set('user', currentUser.toPointer());
        savedCause.set('cause', causeObject);
        await savedCause.save();
      }

      // Notify the parent component to re-fetch the saved causes
      if (onToggleSave) {
        onToggleSave();
      }

    } catch (error) {
      console.error("Error fetching or saving cause:", error);
    }
  };
  const handleCardClick = () => {
    // Normalize the data format
    const causeData = {
        cause_name: data.cause_name,
        short_description: data.short_description,
        volunteer_needs: data.volunteer_needs,
        email_address: data.email_address,
        organization_name: data.organization_name,
        organization_mission: data.organization_mission,
        orgainzation_website: data.organization_website,
        user_interests: data.user_interests,
        user_skills: data.user_skills
    };

    // Step 1: Save the normalized data to sessionStorage
    sessionStorage.setItem('causeData', JSON.stringify(causeData));

    // Step 2: Open a new tab with the target page URL
    window.open(`/cause/${data.id}`, '_blank');
};

  // Ensure that the image URL is correctly extracted if it's a Parse File
  const imageUrl = data.image_1 && typeof data.image_1.url === 'function' ? data.image_1.url() : data.image_1;

  return (
    
      <Card
        hoverable
        cover={imageUrl && <img alt={data.cause_name} src={imageUrl} />}
        style={{ position: 'relative', width: '100%', margin: '2%' }} // Keep relative positioning here
        onClick={() => handleCardClick(data)}
      >
        {currentUser && currentUser.get('role') === 'Volunteer' && (
          <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
            <Button
              type="link"
              icon={isSaved ? <StarFilled style={{ color: '#1890ff' }} /> : <StarOutlined />}
              onClick={toggleSave}
              key="save"
            />
          </div>
        )}
        <Meta title={data.cause_name} />
        {data.organization_pointer && (
          <p>
          <Link to={`/organization/${data.organization_pointer.id}`}>{data.organization_pointer.get('organization_name')}</Link>
          </p>
        )}
        <p>{data.short_description}</p>
        <p><b>Volunteer Opportunities: </b></p>
          <p>{data.volunteer_needs}</p>
        
        
      </Card>
    
  );
};

export default CustomCard;
