import React, { useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, DatePicker, TimePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SubmitEvent = () => {
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [locationLatLong, setLocationLatLong] = useState(null);
  const [address, setAddress] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [image1, setImage1] = useState('');
  const navigate = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Event = Parse.Object.extend('Events');
    const event = new Event();

    event.set('event_name', name);
    event.set('start_time', startTime);
    event.set('end_time', endTime);
    if (locationLatLong) {
        event.set('location_lat_long', new Parse.GeoPoint(locationLatLong.lat, locationLatLong.lng));
      }
    event.set('location_address', address);
    event.set('short_description', shortDescription);
    event.set('long_description', longDescription);
    if (image1) {
        event.set('image_1', image1);
    }
    try {
      await event.save();
      alert('Event submitted successfully!');
      navigate('/');
    } catch (error) {
      alert('Failed to submit event: ' + error.message);
    }
  };

  const handleMapClick = (event) => {
    setLocationLatLong({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      setStartTime(dates[0].toDate());
      setEndTime(dates[1].toDate());
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1>Submit An Event</h1>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Form.Item required>
          <Input value={name} placeholder="Event Name" onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item required>
          <RangePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            onChange={handleRangePickerChange}
          />
        </Form.Item>
        <Form.Item required>
          <div style={{ height: '400px', marginBottom: '16px' }}>
            <GoogleMap
              mapContainerStyle={{ height: '100%', width: '100%' }}
              center={locationLatLong || { lat: 32.741, lng: -117.146 }} // Default to San Diego 32.741, -117.146
              zoom={10}
              onClick={handleMapClick}
            >
              {locationLatLong && <Marker position={locationLatLong} />}
            </GoogleMap>
          </div>
        </Form.Item>
        <Form.Item>
          <Input value={address} placeholder="Address (optional)" onChange={(e) => setAddress(e.target.value)} />
        </Form.Item>
        <Form.Item required>
          <TextArea
            value={shortDescription}
            placeholder="Provide a short description"
            onChange={(e) => setShortDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item required>
          <TextArea
            value={longDescription}
            placeholder="Provide more detail about your event"
            onChange={(e) => setLongDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Image">
          <Upload
            name="image"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                setImage1(e.target.result);
              };
              reader.readAsDataURL(file);
              return false; // Prevent upload
            }}
          >
            {image1 ? <img src={image1} alt="image" style={{ width: '100%' }} /> : <div><PlusOutlined /><div style={{ marginTop: 8 }}>Upload</div></div>}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubmitEvent;
