import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import Parse from 'parse/dist/parse.min.js';

const UserPasswordReset = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetRequest, setIsResetRequest] = useState(true);

  const handlePasswordResetRequest = async (values) => {
    const { email } = values;

    if (!email) {
      message.error('Please provide an email address.');
      return;
    }

    setIsSubmitting(true);
    try {
      await Parse.User.requestPasswordReset(email);
      message.success('Password reset email sent successfully.');
    } catch (error) {
      message.error('Error: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangePassword = async (values) => {
    const { oldPassword, newPassword } = values;

    if (!oldPassword || !newPassword) {
      message.error('Please provide both old and new passwords.');
      return;
    }

    setIsSubmitting(true);
    try {
      const currentUser = Parse.User.current();
      if (currentUser) {
        await Parse.User.logIn(currentUser.getUsername(), oldPassword);
        currentUser.setPassword(newPassword);
        await currentUser.save();
        message.success('Password changed successfully.');
      } else {
        throw new Error('No user is logged in.');
      }
    } catch (error) {
      message.error('Error: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: '0 auto' }}>
      <h2>{isResetRequest ? 'Request Password Reset' : 'Change Password'}</h2>

      {isResetRequest ? (
        <Form layout="vertical" onFinish={handlePasswordResetRequest}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Send Reset Email
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => setIsResetRequest(false)}>
              Change Password Instead
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form layout="vertical" onFinish={handleChangePassword}>
          <Form.Item
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your old password!' }]}
          >
            <Input.Password placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Change Password
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => setIsResetRequest(true)}>
              Request Password Reset Instead
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default UserPasswordReset;