import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import Parse from 'parse';
import { useAuth } from './AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const { Option } = Select;

const SignUp = () => {
  const { login, setCurrentUser } = useAuth();
  const [role, setRole] = useState('Volunteer'); // default role
  const [isParseInitialized, setIsParseInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkParseInitialization = () => {
      const app_id = process.env.REACT_APP_PARSE_APP_ID;
      const host_url = process.env.REACT_APP_PARSE_HOST_URL;
      const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

      if (app_id && host_url && javascript_key) {
        Parse.initialize(app_id, javascript_key);
        Parse.serverURL = host_url;
        setIsParseInitialized(true);
      } else {
        console.error("Parse initialization failed due to missing environment variables");
        setIsParseInitialized(false);
      }
    };

    checkParseInitialization();
  }, []);

  const handleSignup = async (values) => {
    const { username, email, password } = values;

    if (!isParseInitialized) {
        message.error('Parse is not initialized.');
        return;
    }

    try {
      // Clear existing session data
      await Parse.User.logOut();
      localStorage.removeItem('currentUser');
      
      const user = new Parse.User();
      user.set('username', username);
      user.set('email', email);
      user.set('password', password);
      user.set('role', role);

      await user.signUp();
      message.success('Welcome! Thanks for joining.');

      // Use the login function from AuthContext to log in the user
      await login(username, password);

      // Retrieve the 'redirectTo' parameter from the URL
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get('redirectTo');

      // Redirect back to the original page or to the homepage
      navigate(redirectTo || '/');
      
    } catch (error) {
      message.error('Error: ' + error.message);
    }
  };
  console.log('sessionStorage causeData:', sessionStorage.getItem('causeData'));

  return (
    <div style={{ maxWidth: 400, margin: '0 auto' }}>
      <h2>Sign Up</h2>
      <Form layout="vertical" onFinish={handleSignup}>
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>


        <Form.Item label="Role" name="role">
          <Select defaultValue="Volunteer" onChange={(value) => setRole(value)}>
            <Option value="Volunteer">Volunteer</Option>
            <Option value="Organization">Organization</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignUp;
