import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import './App.css';
import { Row, Col, Spin, Button } from 'antd';
import CustomCard from './CustomCard';
import { useAuth } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';

const MyCauses = () => {
  const { currentUser, isInitialized } = useAuth();
  const [causes, setCauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedCauses, setSavedCauses] = useState([]);
  const navigate = useNavigate();

  const fetchSavedCauses = async () => {
    if (currentUser && currentUser.get('role') === 'Volunteer') {
      const SavedCauses = Parse.Object.extend('SavedCauses');
      const savedQuery = new Parse.Query(SavedCauses);
      savedQuery.equalTo('user', currentUser.toPointer());
      savedQuery.include('cause');
      const savedResults = await savedQuery.find();
      const savedIds = savedResults.map(sc => sc.get('cause').id);
      setSavedCauses(savedIds);
    }
  };

  useEffect(() => {
    const fetchCauses = async () => {
      if (!isInitialized || !currentUser) return;

      setLoading(true);

      if (currentUser.get('role') === 'Organization') {
        const Causes = Parse.Object.extend('Causes');
        const query = new Parse.Query(Causes);
        const Organizations = Parse.Object.extend('Organizations');
        const orgQuery = new Parse.Query(Organizations);
        
        orgQuery.equalTo('owner', currentUser.toPointer());
        const organization = await orgQuery.first();
        if (organization) {
          query.equalTo('organization_pointer', organization);
        }

        query.include('organization_pointer'); // Include organization_pointer to get the related organization data

        try {
          const results = await query.find();
          console.log("Fetched Causes for Organization:", results);
          setCauses(results);
        } catch (error) {
          console.error('Error while fetching causes', error);
        }
      } else if (currentUser.get('role') === 'Volunteer') {
        await fetchSavedCauses();
        const SavedCauses = Parse.Object.extend('SavedCauses');
        const savedQuery = new Parse.Query(SavedCauses);
        savedQuery.equalTo('user', currentUser.toPointer());
        savedQuery.include('cause'); // Include the cause pointer
        const results = await savedQuery.find();
        const savedCauses = results.map(result => result.get('cause'));
        setCauses(savedCauses);
      }

      setLoading(false);
    };

    if (isInitialized && currentUser) {
      fetchCauses();
    }
  }, [isInitialized, currentUser]);

  const handleEdit = (causeId) => {
    navigate(`/submit-cause/${causeId}`);
  };

  return (
    <div className="cards">
      <h1>My Causes</h1>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="cards-container">
          {causes.length < 1 ? (
              <div className="no-causes-message">
                <h3>No causes found.</h3>
                <p>To explore causes check out <Link to="/all-causes">local causes</Link></p>
              </div>
          ):(
            causes.map((cause) => (
              <div key={cause.id} className="card">
                <CustomCard
                  type="Cause"
                  data={{
                    cause_name: cause.get('cause_name'),
                    id: cause.id,
                    short_description: cause.get('short_description'),
                    organization_pointer: cause.get('organization_pointer'),
                    image_1: cause.get('image_1'),
                    volunteer_needs: cause.get('volunteer_needs'),
                    donation_needs: cause.get('donation_needs'),
                    email_address: cause.get('cause_contact_email')
                  }}
                  isSaved={savedCauses.includes(cause.id)} // Pass the saved status
                  onToggleSave={fetchSavedCauses} // Callback to refresh saved causes
                  actions={currentUser.get('role') === 'Organization' ? [
                    <Button type="primary" onClick={() => handleEdit(cause.id)}>Edit</Button>
                  ] : []}
                />
              </div>
            ))
          ) }
        </div>
      )}
    </div>
  );
};

export default MyCauses;
