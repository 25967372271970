import React, { useEffect, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import './IndexPage.css';
import { Row, Col, Select, Spin } from 'antd';
import CustomCard from './CustomCard';
import { useAuth } from './AuthContext';
import Chat from './Chat'; 

const { Option } = Select;

const AllCauses = () => {
  const { currentUser, isInitialized } = useAuth();
  const [causes, setCauses] = useState([]);
  const [interests, setInterests] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savedCauses, setSavedCauses] = useState([]);
  const [botCauses, setBotCauses] = useState([]);


  const fetchSavedCauses = async () => {
    if (currentUser && currentUser.get('role') === 'Volunteer') {
      const SavedCauses = Parse.Object.extend('SavedCauses');
      const savedQuery = new Parse.Query(SavedCauses);
      savedQuery.equalTo('user', currentUser.toPointer());
      savedQuery.include('cause');
      const savedResults = await savedQuery.find();
      const savedIds = savedResults.map(sc => sc.get('cause').id);
      setSavedCauses(savedIds);
    }
  };

  useEffect(() => {
    const fetchInterests = async () => {
      if (!isInitialized) return;
      const InterestsList = Parse.Object.extend('InterestsList');
      const query = new Parse.Query(InterestsList);
      try {
        const results = await query.find();
        const interestsData = results.map(interest => ({
          id: interest.id,
          name: interest.get('interest_name')
        }));
        setInterests([{ id: 'all', name: 'All' }, ...interestsData]);
      } catch (error) {
        console.error('Error while fetching interests', error);
      }
    };

    const fetchCauses = async (interest) => {
      if (!isInitialized) return;

      setLoading(true);
      const Causes = Parse.Object.extend('Causes');
      const query = new Parse.Query(Causes);
      if (interest && interest !== 'all') {
        const InterestsList = Parse.Object.extend('InterestsList');
        const interestPointer = new InterestsList();
        interestPointer.id = interest;
        query.equalTo('interest_1', interestPointer);
      }
      query.include('organization_pointer'); // Include organization_pointer to get the related organization data
      try {
        const results = await query.find();
        setCauses(results);

       // Fetch saved causes for the current user
       if (currentUser && currentUser.get('role') === 'Volunteer') {
        await fetchSavedCauses();
      }


      } catch (error) {
        console.error('Error while fetching causes', error);
      }
      setLoading(false);
    };

    if (isInitialized) {
      fetchInterests();
      fetchCauses(selectedInterest);
    }
  }, [isInitialized, selectedInterest, currentUser]);

  const handleInterestChange = (value) => {
    setSelectedInterest(value);
  };

  const handleOptionsReceived = (options) => {
    setBotCauses(options);
  };

  

  return (
    
    <div className="cards">
     
        

      {/* */}
      <h1>Featured Environmental Causes in San Diego</h1>
      <p>Find a cause to support. Sign in to follow a cause.</p>
      <Select
        style={{ width: 200, marginBottom: 16 }}
        placeholder="Select interest"
        onChange={handleInterestChange}
        allowClear
      >
        {interests.map((interest) => (
          <Option key={interest.id} value={interest.id}>
            {interest.name}
          </Option>
        ))}
      </Select>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="cards-container">
          {causes.length > 0 ? (
            causes.map((cause) => (
              <div key={cause.id} className="card">
                <CustomCard
                  type="Cause"
                  data={{
                    cause_name: cause.get('cause_name'),
                    id: cause.id,
                    short_description: cause.get('short_description'),
                    long_description: cause.get('long_description'),
                    organization_pointer: cause.get('organization_pointer'),
                    image_1: cause.get('image_1'),
                    volunteer_needs: cause.get('volunteer_needs'),
                    donation_needs: cause.get('donation_needs'),
                    link: `/cause/${cause.id}`,
                    email_address: cause.get('cause_contact_email')
                  }}
                  isSaved={savedCauses.includes(cause.id)}
                  onToggleSave={fetchSavedCauses}
                />
              </div>
            ))
          ) : (
            <p>No causes found.</p>
          )}
        </div>
      )}
    </div>
  );
  
  
};

export default AllCauses;
