import OpenAI from "openai";
import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, List, Card } from 'antd';
import ReactMarkdown from 'react-markdown';
import './ChatDetail.css';

const openaiApi = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({ apiKey: openaiApi, dangerouslyAllowBrowser: true });

const ChatDetail = ({ organizationName, organizationWebsite, userInterests, userSkills }) => {
    const [messages, setMessages] = useState([{ user: 'Bot', text: 'What else would you like to know?' }]);
    const [input, setInput] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const messagesEndRef = useRef(null); 

    // Scroll to the bottom of the chat
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    //Functiong to prevent 'enter' from creating a new line
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault(); // Prevents adding a new line
        sendMessage(); // Calls the send function
      }
    };

    useEffect(() => {
        scrollToBottom();  // Scroll whenever messages change
    }, [messages]);

    // Start thread with the assistant
    const startThreadWithAssistant = async (assistantId) => {
        try {
            const thread = await openai.beta.threads.create();

            // Send the system message to provide context to the assistant
            await openai.beta.threads.messages.create(thread.id, {
                role: "user",
                content: `The organization you are discussing is ${organizationName}. Their website is ${organizationWebsite}. The user has provided these skills: ${userSkills} and these interests: ${userInterests}. Use this information for all context in the conversation.`,
            });


            const run = await openai.beta.threads.runs.create(thread.id, {
                assistant_id: assistantId,
            });
            setThreadId(thread.id);
        } catch (error) {
            console.error('Error starting thread with assistant:', error);
        }
    };

    const sendMessage = async () => {
        if (!input || !threadId) return;
    
        const newMessages = [...messages, { user: 'You', text: input }];
        setMessages(newMessages);
        setInput('');
        setIsLoading(true);
    
        let fullResponse = ''; // Track full response outside of React state
    
        try {
          await openai.beta.threads.messages.create(threadId, {
            role: "user",
            content: input,
          });
    
          setMessages((prevMessages) => [...prevMessages, { user: 'Bot', text: '' }]);
    
          // Start streaming the assistant's response
          const run = openai.beta.threads.runs.stream(threadId, {
            assistant_id: 'asst_9jqSGMumlfRS0P4uN25CnIzD',
          })
          .on('textCreated', () => {
            console.log('Streaming started');
          })
          .on('textDelta', (textDelta) => {
            fullResponse += textDelta.value; // Append the streamed text to fullResponse
    
            // Update the bot's message incrementally with the full streamed text
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1].text = fullResponse; // Update the last message with the full response so far
              return updatedMessages;
            });
          })
          .on('toolCallCreated', (toolCall) => {
            console.log(`Tool call: ${toolCall.type}`);
          })
          .on('toolCallDelta', (toolCallDelta) => {
            // Handle tool call deltas (if any)
            if (toolCallDelta.type === 'code_interpreter') {
              if (toolCallDelta.code_interpreter.input) {
                console.log(`Code input: ${toolCallDelta.code_interpreter.input}`);
              }
              if (toolCallDelta.code_interpreter.outputs) {
                console.log("Code outputs:");
                toolCallDelta.code_interpreter.outputs.forEach(output => {
                  if (output.type === "logs") {
                    console.log(`Logs: ${output.logs}`);
                  }
                });
              }
            }
          });
    
        } catch (error) {
          console.error('Error streaming response:', error);
          setMessages([...newMessages, { user: 'Bot', text: 'Sorry, something went wrong.' }]);
        } finally {
          scrollToBottom();
          setIsLoading(false);
        }
      };

    // Start the thread when the component mounts
    useEffect(() => {
        const assistantId = 'asst_9jqSGMumlfRS0P4uN25CnIzD';
        startThreadWithAssistant(assistantId);
    }, []);

    return (
        <div className="chat-wrapper"> 
            
                <div className="chat-container">
                    <div className="messages-container">
                        <List
                            dataSource={messages}
                            renderItem={(message) => (
                                <List.Item className={`message-item ${message.user === 'You' ? 'message-user' : 'message-bot'}`}>
                                    <div className={`message-${message.user === 'You' ? 'user' : 'bot'}`}>
                                        {/* Render Markdown for bot messages */}
                                        {message.user === 'Bot' ? (
                                        <ReactMarkdown 
                                          className="markdown-message"
                                          components={{
                                            p: ({ node, ...props }) => <span {...props} />, // Render paragraphs as spans inside lists
                                            
                                          }}
                                          
                                          >
                                          
                                          
                                          {message.text}
                                          </ReactMarkdown>
                                        ) : (
                                        message.text
                                        )}
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="input-container">
                        <Input.TextArea
                            className="chat-input"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Type your message..."
                            autoSize={{ minRows: 1, maxRows: 5 }}
                        />
                        <Button className="send-button" onClick={sendMessage}>
                            →
                        </Button>
                    </div>
                </div>
            
        </div>
    );
};

export default ChatDetail;