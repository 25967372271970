import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import { Card, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const OrganizationDetails = () => {
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    const fetchOrganization = async () => {
      const Organizations = Parse.Object.extend('Organizations');
      const query = new Parse.Query(Organizations);
      try {
        const result = await query.get(id);
        setOrganization(result);
      } catch (error) {
        console.error('Error while fetching organization', error);
      }
    };

    fetchOrganization();
  }, [id]);

  if (!organization) return <p>Loading...</p>;

  const imageFile = organization.get('image_1');
  const imageUrl = imageFile ? imageFile.url() : null;

  return (
    <div className="organization-details">
      <Card style={{ maxWidth: 800, margin: '0 auto' }}>
        <Title level={2}>{organization.get('organization_name')}</Title>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={organization.get('organization_name')}
            style={{ width: '40%', marginBottom: 16 }}
          />
        )}
        <Paragraph>{organization.get('mission')}</Paragraph>
      </Card>
    </div>
  );
};

export default OrganizationDetails;
