import React, { useState } from 'react';
import { Button } from 'antd';
import OpenAI from 'openai';

const openaiApi = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({ apiKey: openaiApi, dangerouslyAllowBrowser: true });

const EmailAssistant = ({ userInterests, userSkills, setRecommendedEmailMessage }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateEmailMessage = async () => {
    setIsLoading(true);

    try {
      // Create a new thread with OpenAI
      const thread = await openai.beta.threads.create();

      // Send context to the assistant about the user's skills and interests
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: `Generate a polite email recommending how the user can contribute to the organization based on their skills: ${userSkills}, and interests: ${userInterests}.`,
      });

      // Start the assistant to generate the response
      const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
        assistant_id: 'asst_p4NbY6F4VJW0QtT4PkULN2kz', // Use your assistant ID here
      });

      // Poll for the completion of the assistant's response
      if (run.status === 'completed') {
        const messagesResponse = await openai.beta.threads.messages.list(run.thread_id);
        const generatedMessage = messagesResponse.data[0].content[0].text.value.trim();

        // Log the response to verify it's correct
        console.log('Generated message from API:', generatedMessage);

        // Log here to verify the state update is being called
        console.log('Setting recommendedEmailMessage:', generatedMessage);

        // Set the generated message as the recommended email message
        setRecommendedEmailMessage(generatedMessage);
    } else {
        console.log('API response status:', run.status);
      }
    } catch (error) {
      console.error('Error generating message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button type="primary" onClick={generateEmailMessage} loading={isLoading}>
      Generate Email Message
    </Button>
  );
};

export default EmailAssistant;