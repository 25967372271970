import React from 'react';
import { Button, Typography, message } from 'antd';
import { useAuth } from './AuthContext';  // Assuming you're using the same AuthContext as in your Login.js
import { Link, useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;



const UserProfile = () => {
  const { currentUser, logout } = useAuth();  // Fetch current user details from AuthContext
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      message.success('You have successfully logged out.');
      navigate('/');
    } catch (error) {
      message.error('Failed to log out. Please try again.');
      console.error('Logout error:', error);
    }
  };

  if (!currentUser) {
    return <p>Loading user information...</p>;  // In case the user isn't loaded yet
  }

  const username = currentUser.get('username');  // Assuming Parse user has 'username'
  const email = currentUser.get('email');  // Assuming Parse user has 'email'
  const role = currentUser.get('role'); 

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <Title level={2}>My Profile</Title>
      <Paragraph>
        <strong>Username:</strong> {username}
      </Paragraph>
      <Paragraph>
        <strong>Email:</strong> {email}
      </Paragraph>
      <Paragraph>
        <strong>Role:</strong> {role}
      </Paragraph>
      
      {/* Password reset button, linking to the reset page */}
      <Link to="/reset">
        <Button >
          Reset Password
        </Button>
      </Link>
      <Link to="/" onClick={handleLogout}>
        <Button >
          Logout
        </Button>
      </Link>
    </div>
  );
};

export default UserProfile;