import React, { useState, useEffect } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Upload, Checkbox, Select, Spin, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth } from './AuthContext';

const SubmitCause = () => {
  const { currentUser } = useAuth();
  const { id } = useParams(); // Get cause ID from URL parameters
  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [causeContactEmail, setCauseContactEmail] = useState('');
  const [volunteerNeeds, setVolunteerNeeds] = useState('');
  const [volunteerLink, setVolunteerLink] = useState('');
  const [donationNeeds, setDonationNeeds] = useState('');
  const [donationLink, setDonationLink] = useState('');
  const [volunteerChecked, setVolunteerChecked] = useState(false);
  const [donationChecked, setDonationChecked] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image1Preview, setImage1Preview] = useState(null);
  const [interests, setInterests] = useState([]);
  const [primaryInterest, setPrimaryInterest] = useState(null);
  const [secondaryInterest, setSecondaryInterest] = useState(null);
  const [tertiaryInterest, setTertiaryInterest] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInterests = async () => {
      const InterestsList = Parse.Object.extend('InterestsList');
      const query = new Parse.Query(InterestsList);
      try {
        const results = await query.find();
        setInterests(results.map(interest => ({
          id: interest.id,
          name: interest.get('interest_name')
        })));
      } catch (error) {
        console.error('Error fetching interests:', error);
      }
    };

    const fetchOrganization = async () => {
      if (!currentUser) {
        console.error('No current user available.');
        return;
      }

      console.log('Fetching organization for user:', currentUser.id);

      const Organizations = Parse.Object.extend('Organizations');
      const query = new Parse.Query(Organizations);
      query.equalTo('owner', currentUser.toPointer());
      try {
        const result = await query.first();
        if (result) {
          setOrganization(result);
          console.log('Organization fetched:', result);
        } else {
          console.error('No organization found for current user.');
        }
      } catch (error) {
        console.error('Error fetching organization:', error);
      }
    };

    if (currentUser) {
      fetchInterests();
      fetchOrganization();
    }

    if (id) {
      fetchCause(id);
    }
  }, [currentUser, id]);

  const fetchCause = async (causeId) => {
    setLoading(true);
    const Causes = Parse.Object.extend('Causes');
    const query = new Parse.Query(Causes);
    query.include('organization_pointer');
    try {
      const cause = await query.get(causeId);
      setName(cause.get('cause_name'));
      setShortDescription(cause.get('short_description'));
      setLongDescription(cause.get('long_description'));
      setCauseContactEmail(cause.get('cause_contact_email'));
      setVolunteerNeeds(cause.get('volunteer_needs') || '');
      setVolunteerLink(cause.get('volunteer_link') || '');
      setDonationNeeds(cause.get('donation_needs') || '');
      setDonationLink(cause.get('donation_link') || '');
      setVolunteerChecked(!!cause.get('volunteer_needs'));
      setDonationChecked(!!cause.get('donation_needs'));
      setPrimaryInterest(cause.get('interest_1') ? cause.get('interest_1').id : null);
      setSecondaryInterest(cause.get('interest_2') ? cause.get('interest_2').id : null);
      setTertiaryInterest(cause.get('interest_3') ? cause.get('interest_3').id : null);
      // Fetch image as Parse.File and store it directly
        const imageFile = cause.get('image_1');
        if (imageFile) {
          setImage1(imageFile); // Store the Parse.File object directly
          setImage1Preview(imageFile.url());
        }
      setOrganization(cause.get('organization_pointer'));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cause:', error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Causes = Parse.Object.extend('Causes');
    let cause;

    if (id) {
      const query = new Parse.Query(Causes);
      cause = await query.get(id); // Await the result to get the Parse Object instance
    } else {
      cause = new Causes();
    }

    cause.set('cause_name', name);
    cause.set('short_description', shortDescription);
    cause.set('long_description', longDescription);
    cause.set('cause_contact_email', causeContactEmail);
    if (volunteerChecked) {
      cause.set('volunteer_needs', volunteerNeeds);
      cause.set('volunteer_link', volunteerLink);
    } else {
      cause.unset('volunteer_needs');
      cause.unset('volunteer_link');
    }
    if (donationChecked) {
      cause.set('donation_needs', donationNeeds);
      cause.set('donation_link', donationLink);
    } else {
      cause.unset('donation_needs');
      cause.unset('donation_link');
    }
    if (primaryInterest) {
      const primaryInterestPointer = new Parse.Object('InterestsList');
      primaryInterestPointer.id = primaryInterest;
      cause.set('interest_1', primaryInterestPointer);
    }
    if (secondaryInterest) {
      const secondaryInterestPointer = new Parse.Object('InterestsList');
      secondaryInterestPointer.id = secondaryInterest;
      cause.set('interest_2', secondaryInterestPointer);
    }
    if (tertiaryInterest) {
      const tertiaryInterestPointer = new Parse.Object('InterestsList');
      tertiaryInterestPointer.id = tertiaryInterest;
      cause.set('interest_3', tertiaryInterestPointer);
    }
    // Only set the image if it's a new file or if it exists
      if (image1 instanceof Parse.File) {
        cause.set('image_1', image1);
      } else if (image1 && typeof image1 === 'string') {
        // This condition is for handling cases where the image was not replaced
        const existingFile = cause.get('image_1');
        if (existingFile) {
          cause.set('image_1', existingFile);
        }
      }
    if (organization) {
      cause.set('organization_pointer', organization);
    }

    try {
      await cause.save();
      alert('Cause submitted successfully!');
      navigate('/');
    } catch (error) {
      alert('Failed to submit cause: ' + error.message);
    }
  };

  const getFilteredInterests = (excludeIds) => {
    return interests.filter(interest => !excludeIds.includes(interest.id));
  };

  const handleCancel = () => {
    navigate(`/cause/${id}`);
  };

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this cause?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          const Causes = Parse.Object.extend('Causes');
          const query = new Parse.Query(Causes);
          const cause = await query.get(id);
          await cause.destroy();
          message.success('Cause deleted successfully');
          navigate('/my-causes');
        } catch (error) {
          message.error('Failed to delete cause: ' + error.message);
        }
      },
    });
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1>{id ? 'Edit Cause' : 'Submit A Cause'}</h1>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item required>
            <Input value={name} placeholder="Name of your cause" onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          
          <Form.Item required>
            <Input.TextArea
              value={shortDescription}
              placeholder="Provide a short description"
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item required>
            <Input.TextArea
              value={longDescription}
              placeholder="Provide more detail about your cause"
              onChange={(e) => setLongDescription(e.target.value)}
            />
          </Form.Item>

          <Form.Item required label="Primary Interest">
            <Select
              placeholder="Select primary interest"
              onChange={value => setPrimaryInterest(value)}
              value={primaryInterest}
            >
              {getFilteredInterests([secondaryInterest, tertiaryInterest]).map(interest => (
                <Select.Option key={interest.id} value={interest.id}>
                  {interest.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Secondary Interest">
            <Select
              placeholder="Select secondary interest"
              onChange={value => setSecondaryInterest(value)}
              value={secondaryInterest}
            >
              {getFilteredInterests([primaryInterest, tertiaryInterest]).map(interest => (
                <Select.Option key={interest.id} value={interest.id}>
                  {interest.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tertiary Interest">
            <Select
              placeholder="Select tertiary interest"
              onChange={value => setTertiaryInterest(value)}
              value={tertiaryInterest}
            >
              {getFilteredInterests([primaryInterest, secondaryInterest]).map(interest => (
                <Select.Option key={interest.id} value={interest.id}>
                  {interest.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <h3>Help Requested</h3>
          <Form.Item>
            <Checkbox
              checked={volunteerChecked}
              onChange={(e) => setVolunteerChecked(e.target.checked)}
            >
              Volunteer
            </Checkbox>
            <Checkbox
              checked={donationChecked}
              onChange={(e) => setDonationChecked(e.target.checked)}
            >
              Donation
            </Checkbox>
          </Form.Item>

          {volunteerChecked && (
            <>
              <Form.Item required>
                <Input.TextArea
                  value={volunteerNeeds}
                  placeholder="What type of volunteer do you need?"
                  onChange={(e) => setVolunteerNeeds(e.target.value)}
                />
              </Form.Item>
              <Form.Item required>
                <Input
                  value={volunteerLink}
                  placeholder="Volunteer Link: Provide a link for volunteers to apply"
                  onChange={(e) => setVolunteerLink(e.target.value)}
                />
              </Form.Item>
            </>
          )}
          
          {donationChecked && (
            <>
              <Form.Item required>
                <Input.TextArea
                  value={donationNeeds}
                  placeholder="How much do you need to raise?"
                  onChange={(e) => setDonationNeeds(e.target.value)}
                />
              </Form.Item>
              <Form.Item required>
                <Input
                  value={donationLink}
                  placeholder="Donation Link: Provide a link where supporters can donate"
                  onChange={(e) => setDonationLink(e.target.value)}
                />
              </Form.Item>
            </>
          )}


          <Form.Item required label="Who is the main point of contact for volunteers?">
            <Input
              value={causeContactEmail}
              placeholder="Email"
              onChange={(e) => setCauseContactEmail(e.target.value)}
            />
          </Form.Item>
          
            <Form.Item label="Image">
              <Upload
                name="image"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={(file) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const base64 = e.target.result.split(',')[1];
                    const parseFile = new Parse.File(file.name, { base64 });
                    setImage1(parseFile); // Store the Parse.File object

                    // Create a preview URL and set it as the image source
                    setImage1Preview(e.target.result);  // Store the preview URL in state
                  };
                  reader.readAsDataURL(file);
                  return false; // Prevent upload
                }}
              >
                {image1Preview ? (
                  <img src={image1Preview} alt="image" style={{ width: '100%' }} />
                ) : (
                  <div><PlusOutlined /><div style={{ marginTop: 8 }}>Upload</div></div>
                )}
              </Upload>
            </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit}>{id ? 'Update Cause' : 'Submit Cause'}</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleCancel}>Cancel</Button>
          </Form.Item>
          <Form.Item>
            <Button type="danger" onClick={handleDelete}>Delete Cause</Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SubmitCause;
