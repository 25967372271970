import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import { Button, Card, Row, Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const Events = Parse.Object.extend('Events');
      const query = new Parse.Query(Events);
      query.include('organization_pointer'); // Include related organization data
      try {
        const result = await query.get(id);
        setEvent(result);
      } catch (error) {
        console.error('Error while fetching event', error);
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) return <p>Loading...</p>;

  const organization = event.get('organization_pointer');

  return (
    <div className="event-details">
      <Card style={{ maxWidth: 800, margin: '0 auto' }}>
        <Title level={2}>{event.get('event_name')}</Title>
        {event.get('image_1') && (
          <img
            src={event.get('image_1')}
            alt={event.get('event_name')}
            style={{ width: '100%', marginBottom: 16 }}
          />
        )}
        <Paragraph>
          <strong>Organization: </strong>
          <Link to={`/organization/${organization.id}`}>
            {organization.get('organization_name')}
          </Link>
        </Paragraph>
        <Paragraph>Date: {new Date(event.get('start_time')).toLocaleDateString()}</Paragraph>
        <Paragraph>
          Time: {`${new Date(event.get('start_time')).toLocaleTimeString()} - ${new Date(event.get('end_time')).toLocaleTimeString()}`}
        </Paragraph>
        <Paragraph>{event.get('short_description')}</Paragraph>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col>
            <Button type="primary" target="_blank">
              Volunteer
            </Button>
          </Col>
          <Col>
            <Button type="primary" target="_blank">
              Donate
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EventDetails;
